export const apps = [
  'hey',
  'tape',
  'orb',
  'phaver',
  'buttrfly',
  'chainjet',
  'lenspeer',
  't2.world',
  'yup',
  'focalize',
  'bloomers.tv',
  'kaira',
  'orna.art',
  'firefly'
];
