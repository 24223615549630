export const ALLOWED_HTML_HOSTS = [
  'youtube.com',
  'youtu.be',
  'tape.xyz',
  'twitch.tv',
  'kick.com',
  'open.spotify.com',
  'soundcloud.com',
  'oohlala.xyz'
];

export const IGNORED_NFT_HOSTS = ['hey.xyz'];
