export enum OptmisticPublicationType {
  Collect = 'Collect',
  Comment = 'Comment',
  Follow = 'Follow',
  Mirror = 'Mirror',
  NftMintOA = 'NftMintOA',
  Post = 'Post',
  Quote = 'Quote',
  Unfollow = 'Unfollow'
}
